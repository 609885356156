.dashboard {
  padding: 20px;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
  transition: ease;
  min-height: 100vh; /* Ensures it covers full viewport height but can grow */
  height: auto; /* Makes the height adapt based on content */
  overflow-y: auto; /* Allows scrolling if content exceeds viewport height */
  overflow: visible;
}

.drilldownTableContainer{
  width: 100%;
  max-width: 100%;
}

.dashboard-header {
  font-size: 30px;
  font-weight: bold;
  color: #2b343d;
  padding: 10px;
}

.dashboard-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.title {
  font-size: 35px;
  margin-bottom: 20px;
  color: #2b343d;
  text-align: left;
  font-weight: bold;
  padding: 10px;
}

.key-highlights {
  margin-top: 1%;
  margin-bottom: 40px;
  font-family: Arial, sans-serif;
}

.key-highlights h1 {
  font-size: 35px; /* Increase heading size */
  font-weight: bold;
  margin-bottom: 10px;
}

.key-highlights li {
  font-size: 18px; /* Increase list item text size */
  margin-bottom: 8px;
  line-height: 1.5;
}

.key-highlights ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
}

/* Printing styles */
@media print {
  * {
    box-sizing: border-box !important; /* Prevents unexpected layout shifts */
  }

  /* Ensure the dashboard fits within the printable area */
  .dashboard {
      width: 100% !important; /* Prevents overflow */
      max-width: 100% !important;
      background-color: transparent;
      box-shadow: none;
      margin: 0;
      padding: 0;
      overflow: visible !important;
  }

  /* Prevent the title from being too large */
  .title, .dashboard-header {
      
      text-align: center !important; /* Centers title */

  }

  /* Hides interactive elements */
  .year-month-selector, .fund-toggles {
      display: none !important;
  }

  /* Ensure graphs stay side-by-side but within print margins */
  .graphs-container {
    display: flex !important; /* Keeps graphs in a row */
    flex-wrap: nowrap !important; /* Prevents wrapping */
    justify-content: space-between !important; /* Adds space between graphs */
    width: 100% !important;
    max-width: 100% !important;
    gap: 10px !important;
  }

  /* Ensure each graph fits within half of the print page */
  .graphs-container > div {
    width: 48% !important; /* Ensures side-by-side layout */
    max-width: 48% !important;
    margin: 0 !important;
    page-break-inside: avoid !important; /* Prevents graphs from splitting */
  }

  /* Ensure tables print properly */
  .drilldownTableContainer {
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 auto !important;
      overflow: hidden !important;
  }

  /* Ensure tables don't overflow */
  table {
      width: 100% !important;
      max-width: 100% !important;
      table-layout: fixed !important; /* Keeps column widths even */
  }

  /* Make sure the canvas elements (charts) print correctly */
  canvas {
      width: 100% !important;
      height: auto !important;
      max-width: 100% !important;
  }

  /* Adjust font sizes for better readability */
  body {
      font-size: 12px !important;
  }

  span, div, p::before, span, div, p::after {
    content: none !important; /* Removes potential emoji characters */
}

  /* Force elements to fit within the printed page */
  @page {
      size: auto; /* Auto-scale to fit */
      margin: 10mm; /* Adjust print margins */
  }
}

