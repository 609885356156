/* CSS */

.state-revenue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.state-revenue-outputs-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0px 0;
}

.state-revenue-outputs-table th,
.state-revenue-outputs-table td {
  padding: 8px;
  text-align: center;
  font-weight: bold;
  color: #2196f3;
}

.save-state-revenue-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}

.state-revenue-header-buttons {
  display: flex;
  justify-content: right;
}

.revenue-vertical-dot-button {
  font-weight: bold;
  font-size: 20px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer; /* Optional: improves UX */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.revenue-button:hover {
  background-color: #0056b3;
}

/* Revenue container for all items on Revenue tab*/
.revenue-container {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  gap: 20px;
  margin-bottom: 100px;
}

.revenue-container div {
  flex-grow: 1;
  flex-basis: 30%; /* Adjust individually */
}

.revenue-container div:nth-child(1) {
  flex-basis: 8%; /* Make it wider */
}

.revenue-container div:nth-child(2) {
  flex-basis: 15%; /* Make it wider */
}

.revenue-container div:nth-child(3) {
  flex-basis: 8%; /* Make it wider */
}

.revenue-container div:nth-child(4) {
  flex-basis: 100%; /* Full width */
}

.revenue-container div:nth-child(5) {
  flex-basis: 20%; /* Wider */
}

.revenue-container div:nth-child(6) {
  flex-basis: 20%; /* Wider */
}

.revenue-container div:nth-child(7) {
  flex-basis: 20%; /* Wider */
}
/* ----------------------------------------- */

.bottom-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0px;
}

.card-local-revenue {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.av-card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
}

.av-card h3 {
  margin: 10px;
  color: #333;
}

.av-card input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  min-width: 110px;
  max-width: 130px;
}

.av-table-button {
  display: flex; /* This makes the parent a flex container */
  flex-direction: row; /* Align children horizontally */
  align-items: center;
}

.add-county-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 40px;
}

.add-county-btn:hover {
  background-color: #e0e0e0;
}

.assessed-values-table {
  justify-items: center;
}

.assessed-values-table table{
  border-collapse: collapse;
  border: none;
  margin: 0 auto; /* Center the table itself */
}

.assessed-values-table th,
.assessed-values-table td {
  border: none;
}

.levy-input-group {
  display: flex;
  gap: 0px; /* spacing between label and input */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.levy-input-group label {
  width: 120px; /* Fixed width for labels */
  margin-right: 0px;
  text-align: left;
}

.levy-input-group input {
  flex-grow: 1;
  width: 200px; /* Fixed width for inputs */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tax-collection-input-group {
  display: flex;
  gap: 15px; /* spacing between label and input */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tax-collection-input-group label {
  width: 120px; /* Fixed width for labels */
  margin-right: 10px;
  text-align: left;
}

.tax-collection-input-group input {
  flex-grow: 1;
  width: 200px; /* Fixed width for inputs */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.levy-card {
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.levy-card h3 {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 0px;
  color: #333;
}

.levy-table {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
}

.levy-table input{ 
  flex: 1; /* Takes remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

.tax-collection-card{
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tax-collection-card h3 {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 0px;
  color: #333;
}

.tax-collection-table {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
}

.tax-collection-table input{ 
  flex: 1; /* Takes remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}



.classroom-trust-grid-container {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 10px 10px 10px; /* Adjust spacing as needed */
  max-width: 400px; /* Adjust width as needed */
}

.classroom-trust-grid-container label, .classroom-trust-grid-container span{
  height: 30px;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%; /* Ensures inputs take full width */
}

.classroom-trust-grid-container input, .total-span{
  margin-left: 30px;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
  width: 60%; /* Ensures inputs take full width */
}

.two-fund-grid-container {
  display: grid;
  grid-template-columns: auto 1fr 1fr; /* Single column */
  gap: 10px; /* Adjust spacing as needed */
  max-width: 400px; /* Adjust width as needed */
}

.two-fund-grid-container label, .two-fund-grid-container span{
  height: 30px;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%; /* Ensures inputs take full width */
}

.two-fund-grid-container input, .total-span {
  margin-left: 30px;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
  width: 60%; /* Ensures inputs take full width */
}

.classroom-trust-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.propc-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.basic-formula-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summer-school-container {
  display: flex;
  gap: 10px; /* Space between the input fields */
}

.state-revenue-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 1045px;
}

.state-revenue-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 20px 0;
}

.state-revenue-table th,
.state-revenue-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.state-revenue-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.state-revenue-table input {
  width: 100%;
  border: none;
  text-align: center;
}


.state-revenue-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.local-property-tax-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.vocation-tuition-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.card-local-revenue h3 {
  margin: 10px;
  color: #333;
}

.card-local-revenue input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.total-local-revenue {
  font-weight: bold;
  color: #2196f3;
  text-align: right;
}

.total-levy {
  font-weight: bold;
  color: #2196f3;
  text-align: right;
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 20px;
  font-size: 18px;
}

.total-av {
  font-weight: bold;
  color: #2196f3;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-top: 40px;
  font-size: 18px;
}

.total-tax-collection {
  margin-top: 10px;
  font-weight: bold;
  color: #2196f3;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 18px;
  line-height: 2.5;
}

.av-county-actions-buttons{
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* CSS for Baseline Salary Grant Component */
.baseline-salary-grant-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure consistent width */
  align-items: flex-start; /* Align content to the left */
}

.baseline-salary-grant-container h3 {
  margin-bottom: 10px;
  color: #333;
}

.project-code-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.project-code-section label {
  font-weight: bold;
  color: #555;
}

.baseline-salary-grant-container button {
  padding: 10px 15px;
  background: #2196f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.baseline-salary-grant-container button:hover {
  background: #1769aa;
}

.total-baseline-grant {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.header-with-tooltip {
  display: flex;
  align-items: center; /* Aligns the question mark with the text */
  gap: 10px; /* Adds spacing between the header and the icon */
  align-items: flex-start; /* Ensures content aligns at the top */
  justify-content: flex-start; /* Ensures it doesn't push downward */
}

.header-with-tooltip h3 {
  margin: 0;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.revenue-info-icon {
  font-size: 10px;
  color: #888;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #888;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.revenue-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  width: 200px; /* Set a fixed width */
  max-width: 250px; /* Or limit max width */
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Ensure long words break properly */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transform: translateX(-50%);
  left: 50%;
  bottom: 30px;
  z-index: 100;
}

.revenue-tooltip.visible {
  visibility: visible;
  opacity: 1;
}

.state-rev-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.state-rev-modal {
  flex-shrink: 0;
  background-color: whitesmoke;
  width: 100px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px; /* Adjust as needed */
  max-width: 90%;
  min-width: 300px; /* Ensures it's not too small */
}


.state-rev-modal h2 {
  margin-top: 0;
  text-align: center;
}

.state-rev-modal-input {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.state-rev-modal-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.state-rev-modal-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.state-rev-modal-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.state-rev-modal-button-group button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.state-rev-modal-button-group button:first-child {
  background: #ccc;
}

.state-rev-modal-button-group button:last-child {
  background: #007bff;
  color: white;
}

.state-rev-modal-button-group button:hover {
  opacity: 0.8;
}
