.staff-management-container {
  font-family: Arial, sans-serif;
  width: 95%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: none;
  padding: 20px;
}

.staff-nested-container {
  font-family: Arial, sans-serif;
  width: 97%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: none;
  padding: 20px;
}

.staff-table-container {
  margin: 10px;
  font-family: Arial, sans-serif;
  width: 98%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: 1px solid #cddbe3; /* Subtle border to match card theme */
  border-radius: 8px; /* Rounded corners for a modern card-like feel */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
  padding: 0px;
  max-height: 500px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.certified-staff-table-container {
  margin: 10px;
  font-family: Arial, sans-serif;
  width: 98%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: 1px solid #cddbe3; /* Subtle border to match card theme */
  border-radius: 8px; /* Rounded corners for a modern card-like feel */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
  padding: 0px;
  max-height: 500px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.details-btn {
  margin-top: 5px;
}

.benefits-checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the container */
  align-items: flex-start; /* Align items to the left */
}
.add-row-btn {
  background-color: #3182ce; /* BalanceHQ button blue */
  color: white;
  padding: 8px 12px; /* Slightly larger for better usability */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.add-row-btn:hover {
  background-color: #2769a5; /* Darker blue for hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}

.staff-management-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners */
  overflow-x: auto;
  border: 1px solid #cddbe3; /* Match container border */
  background-color: #ffffff; /* White background for contrast */
  margin-bottom: 3px;
  margin: 5px;
  box-shadow: 0 0 4px rgba(49, 130, 206, 0.5);
}

.certified-staff-table {
  width: 99%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners */
  overflow-x: auto;
  border: 1px solid #cddbe3; /* Match container border */
  background-color: #ffffff; /* White background for contrast */
  margin-bottom: 3px;
  margin: 5px;
  box-shadow: 0 0 4px rgba(49, 130, 206, 0.5);
}

.certified-staff-table th {
  position: sticky;
  top: 0; /* Sticky header to keep the header in view while scrolling */
  z-index: 2;
}

.staff-management-table th {
  position: sticky;
  top: 0; /* Sticky header to keep the header in view while scrolling */
  z-index: 2;
}

.staff-management-table th,
.staff-management-table td {
  border: 1px solid #cddbe3; /* Subtle border for cells */
  padding: 10px; /* Slightly larger padding for better readability */
  text-align: center;
  font-size: 14px;
  color: #4a5568; /* BalanceHQ text color */
}

.staff-management-table th {
  background-color: #c9eafc; /* Slightly darker blue for header */
  font-weight: bold;
  font-size: 14px;
}

.staff-management-table tr:nth-child(even) {
  background-color: #f6fbff; /* Soft blue for striped rows */
}

.staff-management-table tr:hover {
  background-color: #edf8ff; /* Slightly darker blue on hover */
}

.staff-management-table input[type="text"],
.staff-management-table input[type="number"],
.staff-management-table select {
  width: 95%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #cddbe3; /* Match table borders */
  border-radius: 4px;
  font-size: 14px;
  color: #4a5568;
  background-color: #ffffff; /* White input background */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  min-width: 65px;
}

  .inputs-card {
    background-color: #e7f4fc; /* BalanceHQ background */
    border: 1px solid #cddbe3; /* Subtle border */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
    padding: 20px;
    margin-bottom: 20px;
    width: 50%
  }
  
  .inputs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px;
  }

  .benefits-inputs-container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Aligns everything vertically */
    gap: 15px; /* Space between items */
    background-color: #e7f4fc; /* BalanceHQ background */
    border: 1px solid #cddbe3; /* Subtle border */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
    padding: 20px;
    margin-bottom: 20px;
    width: 45%
  }
  
  .benefits-inputs-container {
  display: flex;
  flex-direction: row;
  align-items: center; /* Aligns everything vertically */
  gap: 15px; /* Space between items */
}

.benefits-input-label {
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

.benefits-dropdown,
.benefits-value-input {
  height: 36px; /* Ensure both have the same height */
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect the height */
}


.benefits-value-input.read-only {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.save-button {
  height: 36px; /* Match dropdown & input height */
  padding: 10px 15px; /* Keep padding consistent */
  font-size: 16px;
  border: 1px solid #ccc; /* Match input & dropdown border */
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box; /* Ensures consistent sizing */
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
  line-height: normal; /* Prevents extra vertical space */
}

.save-button:hover {
  background-color: #0056b3;
}


  
  .benefits-value-input.read-only {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  
  .save-button {
    height: 36px;
    padding: 5px 15px;
    font-size: 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  
  
  .inputs-container div {
    display: flex;
    flex-direction: column; /* Stack label and input vertically */
  }
  
  .inputs-container label {
    font-size: 14px; /* Improve readability */
    font-weight: bold;
    color: #4a5568; /* Darker text for better contrast */
    margin-bottom: 5px;
  }
  
  .inputs-container input {
    padding: 8px;
    border: 1px solid #cddbe3; /* Match card border color */
    border-radius: 4px;
    font-size: 14px;
    color: #4a5568;
    text-align: right;
    background-color: #ffffff; /* Ensure inputs stand out */
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .inputs-container input:focus {
    border-color: #3182ce; /* Highlight on focus */
    box-shadow: 0 0 4px rgba(49, 130, 206, 0.5); /* Subtle focus effect */
  }

  .tableInput {
    margin-left: 3px;
    margin-right: 3px;
  }

  
  /* Modal container styles */
.modal {
  position: fixed; /* Fixed position to overlay the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it overlays other elements */
}

/* Modal content styles */
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal title */
.modal-content h4 {
  margin: 0 0 10px 0;
}

/* Modal actions (buttons) */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.modal-actions button {
  margin-left: 10px;
}

.notification {
  position: fixed;
  top: 5%;
  right: 45%;
  background-color: #4caf50; /* Green for success */
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.benefit-values-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.benefit-values-actions button {
  margin-left: 10px;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}


/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

details {
  cursor: pointer;
  user-select: none;
}

.dropdown-content {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 120px;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.staff-settings-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.staff-settings-modal {
  flex-shrink: 0;
  background-color: whitesmoke;
  width: 100px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px; /* Adjust as needed */
  max-width: 90%;
  min-width: 300px; /* Ensures it's not too small */
}

.staff-settings-modal h2 {
  margin-top: 0;
  text-align: center;
}

.staff-settings-modal-input {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.staff-settings-modal-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.staff-settings-modal-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.staff-settings-modal-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.staff-settings-modal-button-group button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.staff-settings-modal-button-group button:first-child {
  background: #ccc;
}

.staff-settings-modal-button-group button:last-child {
  background: #007bff;
  color: white;
}

.staff-settings-modal-button-group button:hover {
  opacity: 0.8;
}

.staff-settings-menu-item {
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 3px;
}

.staff-settings-menu-item:hover {
  background-color: #f0f0f0;
}