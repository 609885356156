.budget-builder-container {
  width: 92%;
  height: 100%;
  padding: 10px 1.5%; /* Responsive padding */
}

.toggle-arrow {
  cursor: pointer;
  margin-bottom: 10px;
}

.graph-container {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Optional: space between graphs */
  width: 100%;
  height: 50%;
}

.graphs-container{
  width:100%;
  display: flex;
}
.revenue-expenses-graph-container{
  width: 50%;
  height: 500px;
}
.fund-balance-graph-container{
  width: 50%;
  height: 500px;
}

.budget-builder-table {
  width: 98%;
  border-collapse: separate;  /* ✅ Allow border-radius to work */
  border-spacing: 0;  /* ✅ Removes gaps between cells */
  font-size: 16px;
  background-color: #f0f8ff;
  margin-left: 1%;
  border-radius: 10px;  /* ✅ Apply rounding */
  overflow: hidden;  /* ✅ Ensures content respects rounded corners */
}

.budget-filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.budget-filter-modal {
  flex-shrink: 0;
  background-color: whitesmoke;
  width: 100px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px; /* Adjust as needed */
  max-width: 90%;
  min-width: 300px; /* Ensures it's not too small */
}


.budget-filter-modal h2 {
  margin-top: 0;
  text-align: center;
}

.budget-filter-modal-input {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.budget-filter-modal-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.budget-filter-modal-form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.budget-filter-modal-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.budget-filter-modal-button-group button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.budget-filter-modal-button-group button:first-child {
  background: #ccc;
}

.budget-filter-modal-button-group button:last-child {
  background: #007bff;
  color: white;
}

.budget-filter-modal-button-group button:hover {
  opacity: 0.8;
}

.multi-select-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.dropdown-options-container,
.dropdown-search-container,
.dropdown-options,
.search-input {
  box-sizing: border-box;
}

.dropdown-options-container {
  width: 100%;
  position: relative;
}

.dropdown-search-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.search-input {
  width: 100%
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown-option {
  padding: 8px 12px;
}

.dropdown-option label,
.dropdown-option input {
  cursor: pointer
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}

.dropdown-arrow {
  margin-left: 10px;
}

.title-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns the button to the right */
  position: relative;
  width: 100%;
  padding: 0 20px; /* Adjusts spacing */
}

.title-filter-container h2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin: 0;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
  margin-right: 35px;
}

.filter-button {
  background-color: #e0e0e0;
  color: black;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
}

.filter-button:hover {
  background-color: #d6d6d6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-button.active {
  background-color: #87b6a7;; /* Change to blue when filters are applied */
  color: white;
  font-weight: bold;
}

.clear-filters-button {
  background: none;
  border: none;
  color: red;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.clear-filters-button:hover {
  color: darkred;
}