/* General container styles */
.budget-table-container {
    width: 100%;
    max-width: 100%;
    margin: 2% 0;
    background-color: #f0f8ff;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  
  /* Table styles */
  /* Ensure rounded corners are visible */
.budget-table {
    width: 98%;
    border-collapse: separate;  /* ✅ Allow border-radius to work */
    border-spacing: 0;  /* ✅ Removes gaps between cells */
    font-size: 16px;
    background-color: #f0f8ff;
    margin-left: 1%;
    border-radius: 10px;  /* ✅ Apply rounding */
    overflow: hidden;  /* ✅ Ensures content respects rounded corners */
}

/* Apply rounded corners only to the first and last th elements */
.budget-table th:first-child {
    border-top-left-radius: 10px;
}

.budget-table th:last-child {
    border-top-right-radius: 10px;
}

.budget-table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.budget-table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

  
  .budget-table th,
  .budget-table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .budget-table th {
    background-color: #2c3e50;
    color: white;
    text-transform: uppercase;
  }
  
  .budget-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .budget-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Expand/collapse arrow */
  .expand-arrow {
    display: inline-block;
    transition: transform 0.25s ease-in-out;
    margin-right: 5px;
    font-size: 18px;
  }
  
  /* Hierarchical rows */
  .budget-table td {
    font-weight: normal;
  }
  
  .budget-table .group-level td {
    font-size: 18px;
    font-weight: bold;
  }
  
  .budget-table .class-level td {
    font-size: 16px;
    padding-left: 20px;
  }
  
  .budget-table .subclass-level td {
    font-size: 16px;
    padding-left: 40px;
  }
  
  .budget-table .function-level td {
    font-size: 16px;
    padding-left: 60px;
  }

  .filter-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filter-item {
    display: flex;
    flex-direction: column;
  }
  
  .filter-item label {
    margin-bottom: 0.25rem;
  }  
  
  /* Print media styles */
  @media print {
    .expand-arrow {
      display: none !important;
    }

    .budget-table-container {
        width: 95%;
        max-width: 95%;
        margin-left: 1% !important;
        box-shadow: none !important;
      }
  
    .budget-table {
      width: 99%;
      max-width: 99%;
      margin-left: 0.5% !important;
      margin-right: 0.5% !important;
      box-shadow: none !important;
    }

    .budget-table td,
    .budget-table th {
        white-space: normal !important;
        padding: 5px;
        font-size: 14px;
    }
    span, div, p {
      font-family: "Arial", sans-serif !important; /* Forces text-based fonts */
  }
  
    span, div, p::before, span, div, p::after {
      content: none !important; /* Removes potential emoji characters */
  }

    /* Hide filters by default when printing */
    .budget-table-filter {
        display: none !important;
    }
  }
  