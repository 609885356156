.salary-schedule-certified-main {
  background-color: #e7f4fc; 
  padding: 20px;
  border-radius: 8px;
  width: 96%;
  margin: 10px 10px 10px 10px; /* Top Right Bottom Left */
  overflow-x: auto;
}

.certified-salary-schedule-table table {
  table-layout: fixed;
  overflow-x: scroll
}


.certified-salary-schedule-table th, 
.certified-salary-schedule-table td {
  width: 100px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  text-align: center;
}

.certified-salary-schedule-table input {
  width: 100%;  /* Make input fill the cell */
  box-sizing: border-box; /* Ensures padding/border don’t increase width */
  padding: 0;  /* Reduce extra spacing */
  border: none; /* Optional: remove input border */
  text-align: center; /* Ensure consistent alignment */
}

.scroll-button {
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 2s ease-out; /* Adds a fade-out transition */
  }
  
.scroll-button:hover {
  background-color: #0056b3;
}

.scroll-button.right {
  display: none; /* Hidden initially */
}

.scroll-button.left {
  left: 15%;
  display: none; /* Hidden initially */
  opacity: 0; /* Start with opacity 0 */
}

.scroll-right-container {
  top: 30%;
  position: absolute; /* Position relative to parent container */
  right: 1%; /* Same as button */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-right-label {
  margin-bottom: 25px; /* Creates space above the button */
  font-size: 14px;
  color: #333;
}

.scroll-left-container {
  top: 30%;
  position: absolute; /* Position relative to parent container */
  left: 17%; /* Same as button */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-left-label {
  margin-bottom: 25px; /* Creates space above the button */
  font-size: 14px;
  color: #333;
  opacity: 0; /* Start with opacity 0 */
}